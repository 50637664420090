<script setup>
import requests from '../../OfEventRequests';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import OfEntityListHeader from 'of_vue_utils/src/utils/OfEntityListHeader.vue';
import OfTable from 'of_vue_utils/src/utils/OfTable.vue';
import OfPagination from 'of_vue_utils/src/utils/OfPagination.vue';
import OfButton from 'of_vue_utils/src/utils/OfButton.vue';
import OfBusinessPayment from '../modals/OfBusinessPayment.vue'
import { event, searchOptions } from '../../config.js'
import { phraseState, phrasePaymentMethodType, phraseContactType, getStateColor, requestedAnonymized } from '../../functions.js';
import OfCreateBusinessPayment from '../modals/OfCreateBusinessPayment.vue'

const filters = ref({
    searchField: 'COMPANY_NAME',
    Search: '',
})

const businessPayments = ref({
    VALIDATED: {
        pageNumber: 1,
        list: []
    },
    PENDING: {
        pageNumber: 1,
        list: []
    },
    APPROVED: {
        pageNumber: 1,
        list: []
    },
    REJECTED: {
        pageNumber: 1,
        list: []
    },
    MERGED: {
        pageNumber: 1,
        list: []
    },
    searchResults: []
});

const lastPage = ref(false);

const activeBusinessPayment = ref(null);

const activeClaim = ref(null);

const openCreate = ref(false);

const queueMode = ref(null);

const queueModeContactType = ref(null);

const queueCount = computed(() => {
    if (queueMode.value == 'DISPLAYMESSAGE')
        return businessPayments.value.PENDING.counter[1].count + businessPayments.value.APPROVED.counter[1].count - 1
    else if (queueMode.value && businessPayments.value[queueMode.value]) {
        if (event.value.showOnlyPaymentsByBusinessesOnBusinessCrawler && queueMode.value == 'PENDING') {
            return businessPayments.value[queueMode.value].counter[0].count - 1;
        } else {
            return businessPayments.value[queueMode.value].counter[0].count + businessPayments.value[queueMode.value].counter[1].count - 1;
        }
    }
    return null;
})
const searchMode = computed(() => {
    return businessPayments.value && filters.value.Search && filters.value.Search.length;
})
function getBusinessPayments() {
    requests.get.businessPayments(parseQueryParams()).then((result) => {
        businessPayments.value[selectedState.value].list = result;
        lastPage.value = businessPayments.value[selectedState.value].list.length < 20;
    })
}
function searchBusinessPayments() {
    const query = `?searchField=${filters.value.searchField}&searchValue=${filters.value.Search}&pageNumber=1&pageSize=50`;
    requests.get.businessPaymentRequests(query).then((result) => {

        const businessPaymentResults = result.businessPayments;
        const eventPaymentResults = result.eventPayments;

        businessPayments.value.searchResults = businessPaymentResults.concat(eventPaymentResults).sort(function (a, b) {
            return new Date(b.createdTs) - new Date(a.createdTs)
        });
        lastPage.value = true;
    })
}

function extractTvStatus(payment) {
    if (payment.state == 'MERGED') {
        return '';
    } else if (requestedAnonymized(payment)) {
        return 'Anonym';
    } else if (payment.state == 'APPROVED' && !isValidated(payment)) {
        return 'I kø';
    } else if (payment.state == 'PENDING') {
        return 'I kø';
    } else if ((!payment.showOnBusinessCrawler && !payment.showOnIndividualCrawler) || payment.state == 'REJECTED') {
        return 'Vises ikke på TV';
    } else if ((payment.showOnBusinessCrawler || payment.showOnIndividualCrawler) && isValidated(payment)) {
        return 'Vises på TV';
    } else {
        return null;
    }
}
function extractTvStatusColor(payment) {
    if (payment.state == 'MERGED') {
        return '';
    } else if (requestedAnonymized(payment)) {
        return '#a5a5a5';
    } else if (payment.state == 'APPROVED' && !isValidated(payment)) {
        return '#F6AB2F';
    } else if (payment.state == 'PENDING') {
        return '#F6AB2F';
    } else if ((!payment.showOnBusinessCrawler && !payment.showOnIndividualCrawler) || payment.state == 'REJECTED') {
        return '#E6492D';
    } else if ((payment.showOnBusinessCrawler || payment.showOnIndividualCrawler) && isValidated(payment)) {
        return '#38B249';
    } else {
        return null;
    }
}
function isValidated(payment) {
    return payment.state == 'APPROVED' && payment.updatedTs;
}
function parseQueryParams() {
    return `?type=${selectedState.value.toUpperCase()}&pageNumber=${businessPayments.value[selectedState.value].pageNumber}&pageSize=20`;
}
function updateFilters() {
    if (filters.value.Search && filters.value.Search.length) {
        searchBusinessPayments();
    } else {
        getBusinessPayments();
    }
}
function getPage(no) {
    businessPayments.value[selectedState.value].pageNumber = no;

    if (filters.value.Search && filters.value.Search.length) {
        searchBusinessPayments();
    } else {
        getBusinessPayments();
    }
}
async function openBusinessPayment(businessPayment) {
    await releaseClaim();

    requests.post.claimBusinessPayment(businessPayment.businessPaymentGuid).then((businessPayment) => {
        if (businessPayment) {
            activeClaim.value = { claimId: businessPayment.claimId };

            localStorage.setItem('claim', activeClaim.value.claimId)

            activeBusinessPayment.value = businessPayment;
        }
    });
}

const states = ref([{ value: 'VALIDATED' }, { value: 'PENDING' }, { value: 'APPROVED' }, { value: 'REJECTED' }, { value: 'MERGED' }]);

const selectedState = ref('VALIDATED');

function closeBusinessPayment(keepParam = false) {
    if (keepParam) {
        skipwatch = true;
    }
    releaseClaim();
    activeBusinessPayment.value = null;

}
async function releaseClaim() {

    if (activeClaim.value && activeBusinessPayment.value) {
        await requests.post.releaseBusinessPayment(activeBusinessPayment.value.businessPaymentGuid, activeClaim.value);
        activeClaim.value = null;
        localStorage.removeItem('claim')
    } else if (localStorage.getItem('claim') && activeBusinessPayment.value) {
        await requests.post.releaseBusinessPayment(activeBusinessPayment.value.businessPaymentGuid, { claimId: localStorage.getItem('claim') });
        localStorage.removeItem('claim')
    } else if (!activeBusinessPayment.value && localStorage.getItem('claim')) {
        const claimId = localStorage.getItem('claim')

        const claimedBusinessPayment = await requests.get.claimedBusinessPayment(claimId);

        if (claimedBusinessPayment && claimId) {
            await requests.post.releaseBusinessPayment(claimedBusinessPayment.businessPaymentGuid, { claimId: claimId })
        }
        localStorage.removeItem('claim')
    }

}
function reload() {
    if (filters.value.Search && filters.value.Search.length) {
        searchBusinessPayments();
    } else {
        getBusinessPayments();
    }
    countBusinessPayments()
}
function countBusinessPayments() {
    requests.get.countBusinessPayments().then(result => {

        businessPayments.value.VALIDATED.counter = result.VALIDATED;

        businessPayments.value.PENDING.counter = result.PENDING;

        businessPayments.value.APPROVED.counter = result.APPROVED;

        businessPayments.value.REJECTED.counter = result.REJECTED;

        businessPayments.value.MERGED.counter = result.MERGED;

    });
}
async function getActiveBusinessPayment() {

    const params = new URLSearchParams(window.location.search);

    const businessPaymentGuid = params.get('businessPayment');

    const claimId = localStorage.getItem('claim');

    let businessPayment = null;

    if (claimId) {
        businessPayment = await requests.get.claimedBusinessPayment(claimId);

        if (businessPaymentGuid && businessPayment && businessPaymentGuid != businessPayment.businessPaymentGuid) {
            await requests.post.releaseBusinessPayment(businessPayment.businessPaymentGuid, { claimId: claimId })
            activeClaim.value = null;
            localStorage.removeItem('claim')
        }
    }

    if (businessPaymentGuid) {
        if (activeBusinessPayment.value) {
            closeBusinessPayment(true)
        }
        businessPayment = await requests.get.businessPayment(businessPaymentGuid);
    }

    if (businessPayment) {
        openBusinessPayment(businessPayment);
    }
}
async function promote(eventPaymentGuid) {
    const eventPayment = await requests.post.promoteEventPayment(eventPaymentGuid);

    await reload()

    const promotedPayment = await requests.get.businessPayment(eventPayment.promotedToBusinessPaymentGuid);

    openBusinessPayment(promotedPayment)

}
watch(() => selectedState.value, () => {
    if (filters.value.Search && filters.value.Search.length) {
        searchBusinessPayments();
    } else {
        getBusinessPayments();
    }
})

let skipwatch = false;

watch(() => activeBusinessPayment.value, (businessPayment) => {
    if (!skipwatch) {
        const searchURL = new URL(window.location);
        if (businessPayment && activeBusinessPayment.value.businessPaymentGuid != searchURL.searchParams.get('businessPayment')) {
            searchURL.searchParams.set('businessPayment', businessPayment.businessPaymentGuid);
            window.history.pushState({}, '', searchURL);
        } else if (!businessPayment) {
            const searchURL = new URL(window.location);
            searchURL.searchParams.delete('businessPayment');
            window.history.pushState({}, '', searchURL);
        }
    }
    skipwatch = false;
})
function handlePopState() {
    const searchURL = new URL(window.location);
    const businessPaymentGuid = searchURL.searchParams.get('businessPayment')

    skipwatch = true;

    if (businessPaymentGuid) {
        getActiveBusinessPayment();
    }
    else if (activeBusinessPayment.value) {
        activeBusinessPayment.value = null;
        releaseClaim();
    }

    const queue = searchURL.searchParams.get('queue');

    if (queue) {
        queueMode.value = queue;
    } else {
        queueMode.value = null;
    }
}
async function enterQueueMode(queue, contactType = null) {

    queueMode.value = queue;

    const searchURL = new URL(window.location);
    searchURL.searchParams.set('queue', queue);
    window.history.pushState({}, '', searchURL);

    queueModeContactType.value = contactType;

    await releaseClaim();

    nextInQueue()
}
async function nextInQueue(state = null, repetitions = 0) {

    if (queueMode.value != 'DISPLAYMESSAGE') {
        state = queueMode.value;
    } else {
        state = state ?? 'PENDING';

    }

    await reload();

    console.log(businessPayments.value.PENDING.counter[1].count);

    requests.post.claimBusinessPayment(null, state, queueModeContactType.value).then((nextPayment) => {
        if (nextPayment) {

            reload();

            activeClaim.value = { claimId: nextPayment.claimId };

            localStorage.setItem('claim', nextPayment.claimId)

            activeBusinessPayment.value = nextPayment;
        } else {
            if (queueMode.value == 'DISPLAYMESSAGE' && repetitions < 1) {
                nextInQueue((state == 'PENDING' ? 'APPROVED' : 'PENDING'), repetitions + 1)
            }
        }
    })
}
function exitQueueMode() {
    queueMode.value = null;
    closeBusinessPayment();

    const searchURL = new URL(window.location);
    searchURL.searchParams.delete('queue');
    window.history.pushState({}, '', searchURL);

}
let interval;
let intervalSeconds = ref(60);
onMounted(() => {
    interval = setInterval(() => {
        intervalSeconds.value--;
        if (intervalSeconds.value == 0) {
            intervalSeconds.value = 60;
            reload();
            if (queueMode.value && !activeBusinessPayment.value) {
                nextInQueue();
            }
        }
    }, 1000);

    window.removeEventListener("popstate", handlePopState)

    window.addEventListener("popstate", handlePopState)

    countBusinessPayments()

    getBusinessPayments();

    getActiveBusinessPayment()

    const searchURL = new URL(window.location);
    const queue = searchURL.searchParams.get('queue');

    if (queue) {
        queueMode.value = queue;
    }

})
onUnmounted(() => {
    clearInterval(interval)
})

</script>

<template>
    <OfButton v-if="queueMode" type="button" text="Tilbage" @click="exitQueueMode" />

    <div class="quick-nav" v-if="!queueMode">
        <div @click="enterQueueMode('PENDING', 'BUSINESS')">

            <div>
                Godkend
            </div>
            <div>
                Faktura
            </div>
            <p>
                Godkend faktura til optælling og visningsnavn på TV.
            </p>
            <span v-if="businessPayments.PENDING.counter">
                {{ businessPayments.PENDING.counter[0].count }}
            </span>
        </div>

        <div @click="enterQueueMode('APPROVED', 'BUSINESS')">

            <div>
                Godkend
            </div>
            <div>
                Visningsnavn
            </div>
            <p>
                Godkend til visning på TV.
            </p>
            <span v-if="businessPayments.APPROVED.counter">
                {{ businessPayments.APPROVED.counter[0].count }}
            </span>
        </div>

        <div v-if="event.showOnlyPaymentsByBusinessesOnBusinessCrawler" @click="enterQueueMode('DISPLAYMESSAGE', 'INDIVIDUAL')">

            <div>
                Godkend
            </div>
            <div>
                Seerhilsener
            </div>
            <p>
                Godkend til visning på TV.
            </p>
            <span v-if="businessPayments.PENDING.counter && businessPayments.APPROVED.counter">
                {{ businessPayments.PENDING.counter[1].count + businessPayments.APPROVED.counter[1].count }}
            </span>
        </div>
    </div>

    <div v-if="queueMode && 0 <= 0" class="no-more">
        <h1>Køen er tom</h1>
        <p>Godt klaret!</p>
        <span>Checker igen om {{ intervalSeconds }} sekunder</span>
    </div>
    <div v-else-if="queueMode && !activeBusinessPayment" class="no-more">
        <h1>Køen er tom</h1>
        <p>De(n) resterende {{ businessPayments[queueMode].counter }} donation(er) er under behandling af andre medarbejdere
        </p>
        <span>Checker igen om {{ intervalSeconds }} sekunder</span>
    </div>

    <template v-if="!queueMode">
        <OfEntityListHeader title="Donationer" :default-filters="filters" entity="businessPayment"
            placeholder="Indsæt søgeord" :selectOptions="searchOptions" extra-button="Tilføj manuelt"
            @extra-click="openCreate = true" @updateFilters="updateFilters" />


        <ul class="radio-group" v-if="!searchMode">
            <li v-for="state in states" :key="state.value">
                <div class="control-input">
                    <label :for="state.value">
                        <input :id="state.value" type="radio" :value="state.value" name="selectedState"
                            v-model="selectedState">
                        <div class="check"></div>
                        {{ phraseState(state.value) }} <span v-if="businessPayments[state.value].counter">&nbsp;({{
                            businessPayments[state.value].counter[0].count
                            + businessPayments[state.value].counter[1].count }})</span>
                    </label>
                </div>
            </li>
        </ul>

        <div class="business-payments">
            <OfTable type="entity">
                <template #thead>
                    <th v-if="searchMode">Status</th>
                    <th>CVR-nr.</th>
                    <th>Navn</th>
                    <th>Visningsnavn</th>
                    <th>Beløb</th>
                    <th>TV</th>
                    <th>Kontakttype</th>
                    <th>Betalingsmetode</th>
                </template>

                <template v-if="searchMode" #tbody>
                    <tr v-for="payment in businessPayments.searchResults" @keyup="keyup"
                        v-bind:key="payment.businessPaymentGuid" tabindex="0" :class="getStateColor(payment, 'name')"
                        @click="payment.businessPaymentGuid ? openBusinessPayment(payment) : null; payment.eventPaymentGuid ? promote(payment.eventPaymentGuid) : null;">
                        <td>
                            {{ phraseState(payment) }}
                        </td>
                        <td v-if="payment.contactType == 'BUSINESS'">
                            {{ payment.businessCode }}
                        </td>
                        <td v-else>
                            Privat
                        </td>
                        <td>
                            {{ payment.companyName }}
                        </td>
                        <td>
                            {{ payment.displayName }}
                        </td>
                        <td>
                            {{ payment.amount.toLocaleString('da-DK', {
                                minimumFractionDigits: 0, maximumFractionDigits: 0
                            }) }}
                        </td>
                        <td :style="{ color: extractTvStatusColor(payment) }">
                            {{ extractTvStatus(payment) }}
                        </td>
                        <td>
                            {{ phraseContactType(payment.contactType) }}
                        </td>
                        <td>
                            {{ phrasePaymentMethodType(payment.paymentMethodType) }}
                        </td>
                    </tr>
                </template>

                <template v-else-if="businessPayments" #tbody>
                    <tr v-for="payment in businessPayments[selectedState].list" @keyup="keyup"
                        v-bind:key="payment.businessPaymentGuid" tabindex="0" :class="getStateColor(payment, 'name')"
                        @click="openBusinessPayment(payment)">
                        <td v-if="payment.contactType == 'BUSINESS'">
                            {{ payment.businessCode }}
                        </td>
                        <td v-else>
                            Privat
                        </td>
                        <td>
                            {{ payment.companyName }}
                        </td>
                        <td>
                            {{ payment.displayName }}
                        </td>
                        <td>
                            {{ payment.amount.toLocaleString('da-DK', { minimumFractionDigits: 0, maximumFractionDigits: 0
                            }) }}
                        </td>
                        <td :style="{ color: extractTvStatusColor(payment) }">
                            {{ extractTvStatus(payment) }}
                        </td>
                        <td>
                            {{ phraseContactType(payment.contactType) }}
                        </td>
                        <td>
                            {{ phrasePaymentMethodType(payment.paymentMethodType) }}
                        </td>
                    </tr>
                </template>

                <template #pagination>
                    <OfPagination @paginate="getPage" v-bind:pageNumber="businessPayments[selectedState].pageNumber"
                        :last-page="lastPage" />
                </template>
            </OfTable>
        </div>
    </template>

    <OfBusinessPayment v-if="activeBusinessPayment" @close="closeBusinessPayment" :businessPayment="activeBusinessPayment"
        :claim="activeClaim" :queue-mode="queueMode" :queue-count="queueCount" @reload="reload()"
        @next-in-queue="nextInQueue" @exit-queue-mode="exitQueueMode()" @change-business-payment="openBusinessPayment" />

    <OfCreateBusinessPayment v-if="openCreate" @close="openCreate = false" @reload="reload()" />
</template>

<style scoped>
.quick-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 66vh;
}

.quick-nav>div {
    cursor: pointer;
    text-align: center;
    height: 300px;
    width: 400px;
    background: #fff;
    margin: 1rem;
    color: rgba(0, 0, 0, .35);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .15);
    transition: all .5s ease;
    position: relative;
    padding-top: 30px;
    font-weight: 500;
}

.quick-nav>div>div {
    text-transform: uppercase;
}

.quick-nav>div>div:nth-child(2) {
    font-size: 30px;
    line-height: normal;
    color: rgba(0, 0, 0, .6);
    margin: 0.5rem 0 1rem;
    font-weight: 700;
    transition: all .5s ease;
}

.quick-nav>div>p {
    padding: 0 30px;
    min-height: 70px;
}

.quick-nav>div>span {
    position: absolute;
    top: -12px;
    right: -12px;
    font-size: 14px;
    font-weight: 400;
    height: 36px;
    line-height: 36px;
    min-width: 36px;
    padding: 0 5px;
    border-radius: 18px;
    display: block;
    background: linear-gradient(45deg, rgba(15, 91, 194, .9019607843137255), rgba(58, 177, 230, .9019607843137255));
    color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .25);
}

.no-more {
    text-align: center;
}

.no-more h1 {
    margin: 0 0 1em;
    margin-top: 100px;
    font-size: 6rem;
    line-height: normal;
    margin-bottom: 0;
    color: rgba(0, 0, 0, .5);
}

.no-more p {
    margin: 0 0 1em;
    font-size: 1.7rem;
    line-height: normal;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, .35);
}

.no-more span {
    font-size: 1.2rem;
    line-height: normal;
    color: rgba(0, 0, 0, .35);
}

.radio-group {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.radio-group.vertical {
    flex-direction: column;
}

.radio-group li,
.checkbox-group li {
    margin: 10px 15px 0 0;
}

.radio-group .control-input label {
    display: inline-flex;
    position: relative;
    cursor: pointer;
    align-items: center;
}

.check {
    display: inline-flex;
    float: left;
    margin-top: 0;
    margin-right: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    order: -2;
    height: 14px;
    position: relative;
}

.check:before {
    content: "";
    border-radius: 100%;
    display: block;
    width: 14px;
    top: 0;
    left: 0;
    background-image: linear-gradient(0deg, #f6f7f9, #fff);
    border: 1px solid #d8dce6;
    box-shadow: 0 1px 1px 0 rgb(22 29 37 / 5%), inset 0 2px 0 0 hsl(0deg 0% 100% / 5%);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    transition: .2s;
    height: 14px;
    position: relative;
}

.check:after {
    content: "";
    display: block;
    background: transparent;
    height: 6px;
    width: 6px;
    position: absolute;
    top: 4px;
    z-index: 5;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
}

input[type=radio],
input[type=checkbox] {
    cursor: pointer;
    -webkit-appearance: none;
    position: absolute;
    left: 0;
    width: 100%;
    margin: 0;
    background: transparent;
    border: none;
    cursor: pointer;
}

.radio-group input[type=radio]:checked~.check:before {
    border-color: #149373;
    background: linear-gradient(45deg, rgba(20, 147, 115, .9), rgba(55, 184, 119, .9));
    transition: .2s;
}

.radio-group input[type=radio]:checked~.check:after {
    top: 4px;
    background: #fff;
    transition: .2s;
}
</style>